<template>
  <div>
    <HomePageTop/>
    <!-- <div class="top"></div> -->
    <!--普检精检详细说明 -->
    <div class="head" style="margin-top: -32px">

      <!--      <div class="base-width">-->
      <!--        <h2>RAKUMART 公司费用介绍</h2>-->
      <!--        <p>我们秉承公正、透明的原则制定了以下费用标准，有针对不同等级的客户设定不同的收费标准。</p>-->
      <!--        <p>您的会员等级更高，就能享有更优惠的费用。</p>-->
      <!--      </div>-->
      <div class="base-width">
        <h2>オプションサービス</h2>
        <p>Rakumartでは、さまざまな倉庫（FBA、楽天、Yahooなど）でのラベル貼り付け納品サービスや製品のカスタム撮影、ODM（Original
          Design
          Manufacturer）カスタマイズなど、追加サービスを提供しています。</p>
        <p>独自のプライベートブランドをお持ちであれば、ぜひご連絡ください。</p>
      </div>
    </div>
    <!-- 收费说明 -->
    <div class="collectFee">
      <div class="width920">
        <!--        <h3 style="color:#B4272B;">-->
        <!--          注意：下記定額会員とSVIPの特別価格の適用は２０２３年８月７日からになります。問題などある際は担当者までお問い合わせください！！</h3>-->
        <h3>詳細</h3>
        <!--        <p class="text">{{ $fanyi("RAKUMART在交易的过程中会向客户收取手续费。") }}<br>-->
        <!--          {{ $fanyi("手续费的收取标准是根据订单金额百分比进行计算，百分比大小与客户的会员等级有关。") }}-->
        <!--        </p>-->
        <!--        <p class="text">{{-->
        <!--            $fanyi("请注意手续费只是基本工作的费用。")-->
        <!--          }}<br>{{ $fanyi("对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。") }}-->
        <!--        </p>-->
        <p class="text" style="margin-bottom: 0">RAKUMARTは商品を販売している訳ではございません。</p>
        <p class="text">買付代行サービスとしてお客様に手数料を頂いております。<br>
          手数料は注文金額に基づいて計算されますが、手数料はお客様の会員レベルによって異なります。
        </p>
        <p class="text">下記の料金表に掲載されていない操作やサービスについても柔軟に対応いたしますので、ご質問がある場合はお気軽にお問い合わせください。
        </p>
        <div class="table">
          <div class="row row-title">
            <div class="label">
              <div class="grade">{{ $fanyi("项目") }}</div>
            </div>
            <div class="item">
              <div class="grade">{{ $fanyi("普通会员") }}</div>
            </div>
            <div class="item">
              <div class="grade">{{ $fanyi("定额会员") }}</div>
            </div>
            <div class="item">
              <div class="grade">SVIP</div>
            </div>
          </div>
          <div class="row">
            <div class="label">{{ $fanyi("简易检品") }}</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
          </div>
          <div class="row">
            <!--          <div class="label">{{ $fanyi("详细检品（表+里）") }}</div>-->
            <div class="label " style="flex-direction: column">
              <div class="flex" style="width: 100%;">
                <div>{{ $fanyi("詳細検品") }}</div>
                <div class="colorB4272B cursorPointer" @click="pageJump('/inspectionDetail')">詳細へ</div>
              </div>
              <div style="width: 100%">
                <span class="colorB4272B" style="font-size: 12px;">※（一部例外有り）</span><br>
                <span class="colorB4272B"
                      style="font-size: 12px;display: inline-block;margin-left: 0">単価１５元以下の商品１点1.5元</span><br>
                <span class="colorB4272B"
                      style="font-size: 12px;display: inline-block;margin-left: 0">単価３０元以下の商品１点２元</span>
              </div>
            </div>
            <div class="item">6%</div>
            <div class="item">5%</div>
            <div class="item">4%</div>
          </div>
          <!--          <div class="row" style="height: 30px">-->
          <!--            <div class="label"></div>-->
          <!--            <div class="item"></div>-->
          <!--            <div class="item"></div>-->
          <!--            <div class="item"></div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="label" style="flex-direction: column">-->
          <!--              <div style="width: 100%;">{{ $fanyi("詳細検品（時間換算対応）") }}</div>-->
          <!--              <div style="width: 100%;margin-bottom:10px">-->
          <!--                <span class="colorB4272B" style="font-size: 12px">特殊商品検品：15元/30分</span><br>-->
          <!--                <span class="colorB4272B" style="font-size: 12px;display: inline-block;margin-left: 0">※既定の検品料金で採算が合わない検品について、</span><br>-->
          <!--                <span class="colorB4272B" style="font-size: 12px;display: inline-block;margin-left: 0">時間検品料金を適用させて頂く場合がございます。</span><br>-->
          <!--                <span class="colorB4272B" style="font-size: 12px;display: inline-block;margin-left: 0">例）電器機器等の通電や無線接続確認等</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="item">相談可</div>-->
          <!--            <div class="item">O</div>-->
          <!--            <div class="item">O</div>-->
          <!--          </div>-->
          <div class="row">
            <div class="label">不良品返品/交換手数料</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
          </div>
          <div class="row" style="height: 10px">
            <div class="label"></div>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </div>
          <div class="row">
            <div class="label">
              <div style="width:100%;">
                <span>不良品返品/交換中国国内送料</span><br>
                <span class="colorB4272B" style="font-size: 12px;display: inline-block;margin-left:-6px">（※セラーが受け付けない及びお客様都合の</span><br>
                <span class="colorB4272B" style="font-size: 12px;display: inline-block;margin-left: 1px"> 返品時に発生、明らかな不良はセラーが負担）</span>
              </div>
            </div>
            <div class="item">5元/㎏</div>
            <div class="item">5元/㎏</div>
            <div class="item">5元/㎏</div>
          </div>
          <div class="row">
            <div class="label">
              <div class="flex">
                <div>{{ $fanyi("写真撮影") }}</div>
                <div class="colorB4272B cursorPointer" @click="pageJump('/photography')">詳細へ</div>
              </div>
            </div>
            <div class="item">O</div>
            <div class="item">O</div>
            <div class="item">O</div>
          </div>
          <div class="row">
            <div class="label">
              <div class="flex">
                <div>{{ $fanyi("订单手续费") }}</div>
                <div class="colorB4272B cursorPointer" @click="pageJump('/comisiones-rakumart')">詳細へ</div>
              </div>
            </div>
            <div class="item">商品代金3%-5%</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
          </div>
          <div class="row">
            <div class="label">{{ $fanyi("入RAKUMART仓库费用") }}</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
          </div>
          <div class="row">
            <div class="label">{{ $fanyi("出RAKUMART仓库费用") }}</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
            <div class="item">無料</div>
          </div>
          <!--          <div class="row">-->
          <!--            <div class="label ">-->
          <!--              <div>{{ $fanyi("精细检品（表面）") }}</div>-->
          <!--              <div class="colorB4272B colorB4272B detail" @click="pageJump('/inspectionDetail')">詳細</div>-->
          <!--            </div>-->
          <!--            <div class="item">2元/点</div>-->
          <!--            <div class="item">2元/点</div>-->
          <!--            <div class="item">2元/点</div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="label">-->
          <!--              <div>-->
          <!--                <span>チラシ、感謝手紙など印刷物入れ</span> <br>-->
          <!--                <span class="colorB4272B" style="font-size: 12px;display: inline-block;padding-left: -5px">（1種類のみ、2種類以上はセット作業代請求）</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="item">0.5元/点</div>-->
          <!--            <div class="item">0.3元/点</div>-->
          <!--            <div class="item">無料</div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="label">-->
          <!--              <div>-->
          <!--                <p> {{ $fanyi("商品组套操作费用") }} </p>-->
          <!--                <p>（{{ $fanyi("数量和种类") }}）</p>-->
          <!--                <span style="font-size: 12px;display: inline-block;"-->
          <!--                      class="colorB4272B">※定額会員、SVIPの場合、<br><span-->
          <!--                    style="display: inline-block;">商品内容により個別相談可能</span></span>-->
          <!--              </div>-->

          <!--            </div>-->
          <!--            <div class="item">3点以内:1元 <br/> 4~5点:2元 <br/>6~7点:3元 <br/>8~9点:4元<br/> 10点以上:7元-->
          <!--            </div>-->
          <!--            <div class="item">3点以内:1元 <br/> 4~5点:2元 <br/>6~7点:3元 <br/>8~9点:4元<br/> 10点以上:6元-->
          <!--            </div>-->
          <!--            <div class="item">3点以内:1元 <br/> 4~5点:1.5元 <br/>6~7点:2.5元 <br/>8~9点:3.5元<br/> 10点以上:5元-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="label">RCEP原産地証明書</div>-->
          <!--            <div class="item">150元/回</div>-->
          <!--            <div class="item">150元/回</div>-->
          <!--            <div class="item">150元/回</div>-->
          <!--          </div>-->
        </div>

      </div>
    </div>
    <!-- 检品option费 -->
    <div v-for="(item, index) in optionText" :key="index" :style="index===optionText.length-1?'margin-bottom:30px':''"
         class="option">
      <div class="base-width">
        <h3>{{ $fanyi(item.title) }}</h3>
        <div class="text" v-for="(text, textIndex) in item.textList" :key="textIndex">
          <p v-html="text.text"></p>
        </div>
        <div class="table" v-if="!item.type">
          <div class="row row-title">
            <div class=" label">
              <div class="grade">{{ $fanyi("项目") }}</div>
            </div>
            <div class="item">
              <div class="grade">{{ $fanyi("普通会员") }}</div>
            </div>
            <div class="item">
              <div class="grade">{{ $fanyi("定额会员") }}</div>
            </div>
            <div class="item">
              <div class="grade">SVIP</div>
            </div>
            <!--            v-if="item.title!=='お客様の販売状況に応じ多種な対応サポート'"-->
            <div class="item append">
              {{ $fanyi("视频") }}
            </div>
            <!--            v-if="item.title!=='お客様の販売状況に応じ多種な対応サポート'"-->
            <div class="item append">
              {{ $fanyi("图片") }}
            </div>
          </div>
          <div class="row" v-for="(items, indexs) in item.row" :key="indexs">
            <div class=" label">
              <p v-if="items.label!='倉庫保管'" v-html="items.label"></p>
              <div v-else class="flex">
                <div>倉庫保管</div>
                <div class="colorB4272B cursorPointer" style="margin-left:30px"
                     @click="pageJump('/storageInstructions')">詳細へ
                </div>
              </div>
            </div>
            <div class="item">
              <p v-html="items.val3"></p>
            </div>
            <div class="item">
              <p v-html="items.val2"></p>
            </div>
            <div class="item ">
              <p v-html="items.val1"></p>
            </div>
            <!--            v-if="item.title!=='お客様の販売状況に応じ多種な対応サポート'"-->
            <div class="item append">
              <div v-if="items.video !== ''" class="video" @click="videoPageJump(items.video)">
                <div class="edy-texteditor-container video embed-container edy-positionable-container-maxwidth"
                     contenteditable="false"
                     style="max-width: 100%;pointer-events: none;overflow: hidden;position: relative;width: auto; "
                     unselectable="on">
                  <div class="edy-padding-resizer-wrapper" style="padding-bottom: 56.25%">
                    <iframe id="ifrId" :src="items.video" allowfullscreen="allowfullscreen" frameborder="0" style="
                                              position: absolute;
                                              display: block;
                                              width: 100%;
                                              height: 100%;
                                            "></iframe>
                  </div>
                </div>
                <!--                            </div>-->
              </div>
              <div class="img" v-else><img src="../../assets/zunb.png" alt=""></div>

            </div>
            <!--            v-if="item.title!=='お客様の販売状況に応じ多種な対応サポート'"-->
            <div class="item append">
              <div class="img" v-if="items.img !== ''">
                <el-popover placement="right" trigger="hover">
                  <img :src="items.img" style="width: 300px"/>
                  <img :src="items.img" :class="'img' + (items.id)" @click="imgFullscreen('.' + 'img' + (items.id))"
                       slot="reference" alt="">
                </el-popover>
              </div>
              <div class="img" v-else><img src="../../assets/zunb.png" alt=""></div>
            </div>
          </div>
        </div>
        <!-- 検品について -->
        <div v-else class="inspect">
          <div class="item">
            <div class="img">
              <img src="@/assets/foot-img/optionExplain/des1.png" alt="">
            </div>
            <div class="text">
              <h6>簡易（無料）検品</h6>
              <p>全ての入荷商品に対して、<br> 簡易検品を行っています。</p>
              <p style="color: #B4272B;"> ★注文通りの納品かどうか</p>
              <p style="color: #B4272B;"> ★外観のダメージ有無 </p>
              <p style="color: #B4272B;"> ★商品ページの商品と大きな</p>
              <p>主に上記３点になります。<br> 問題があれば、お客様に報告して返品・返金交渉を無料で行います</p>
              <p style="color: #B4272B ;">※店舗より返品送料負担しない場合があります</p>
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="@/assets/foot-img/optionExplain/des2.png" alt="">
            </div>
            <div class="text">
              <h6>詳細（有料）検品</h6>
              <p>簡易検品は開封しませんが、詳細検品は開封の上、中身を確認します。</p>
              <p>アパレル商品であれば、汚れや傷の有無、チャックの開閉、</p>
              <p>糸きり等の作業も行います。</p>
              <p>利用料は商品金額の６％となります。※（一部例外有り）</p>
              <p>単価１５元以下の商品１点1.5元</p>
              <p>単価３０元以下の商品１点２元</p>
              <p>とさせていただきます。</p>
              <p>問題があれば、お客様に報告して返品・返金交渉を無料で行います。</p>
              <p>商品の採寸、作動、通電検査などは含まれませんのでご注意ください。</p>
              <p>必要の場合はなからず担当者または注文書に記載し、</p>
              <p>別途料金が必要の場合はお客様と再確認になりますので、予めご了承ください。</p>
              <p>検品料金と別に作業時間で請求させていただきます。</p>
              <p>３０分/１５元</p>
              <p style="color: #B4272B;">※店舗より返品送料負担しない場合があります。</p>
              <p style="margin-top: 20px;">更新日：2023年7月17日</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <FootVue/>
  </div>
</template>
<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";
import PicZoom from "vue-piczoom";
import {optionText} from '@/utlis/option-text'

export default {
  data() {
    return {
      optionText,
    };
  },
  components: {
    PicZoom,
    FootVue,
    HomePageTop
  },
  methods: {
    //点击跳转
    pageJump(item) {
      window.open(item)
    },
    videoPageJump(val) {
      window.open(val)
    },
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.base-width {
  width: 1400px;
  margin: 0 auto;
}

.top {
  height: 40px;
  background: #B4272B;
  min-width: 1400px;
}

.head {

  margin: 0 auto;
  height: 350px;
  background-size: 100% 100%;
  background: url('../../assets/footList/servicios-adicionales/option-bg.jpg') no-repeat 50%;
  min-width: 1400px;

  h2 {
    font-size: 36px;
    padding: 70px 0 50px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0px 0px 27px rgba(0, 0, 0, 0.4);
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);

    &:nth-child(2) {
      margin-bottom: 25px;
    }
  }

}

h3 {
  font-size: 30px;
  padding: 80px 0 50px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.vip {
  width: 100%;
  display: flex;
  padding-left: 296px;
  margin-bottom: 18px;

  .item {
    //width: 350px;
    flex: 1;
    border-radius: 10px;
    margin-left: 18px;
    text-align: center;
    background: linear-gradient(0deg, #A6A6A6, #D6D6D6);
    color: #fff;
    padding: 22px 0;

    &:nth-child(2) {
      background: linear-gradient(0deg, #F16E2E, #FFAB82);
    }

    &:nth-child(3) {
      background: linear-gradient(0deg, #DA4E3E, #FF897C);
    }

    .grade {
      font-size: 18px;
      font-weight: bold;

    }
  }
}

.text {
  margin-bottom: 50px;
  line-height: 36px;
  font-size: 18px;
  text-align: center;
}

.collectFee {
  background-color: #fff;
  padding-bottom: 80px;
  min-width: 1400px;

  .width920 {
    width: 920px;
    margin: 0 auto;

    .table {
      border-radius: 10px;
      position: relative;
    }
  }

  .row {
    display: flex;
    padding: 0;


    .label {
      font-size: 16px;
      flex: 1.6 !important;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-indent: 30px;
      position: relative;

      .detail {
        position: absolute;
        top: 0;
        right: 60px;
        padding: 20px 0;

      }
    }

    .item {
      flex: 1;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0 !important;
      text-align: center;
    }

    .item:nth-child(3) {
      background: #FDF7ED;
      color: #684F3E;
    }

    .item:nth-child(4) {
      background: #EDEDFB;
      color: #2F3AC7;
    }
  }

  .row-title {
    font-weight: bold;
    height: 70px;
    position: sticky;
    top: 58px;
    left: 0;
    z-index: 1;

    .label {
      background: #EEEEEE;
    }

    .item:nth-child(2) {
      background: linear-gradient(165deg, #E6E6E6, #D0D0D0);
      color: #333333;
    }

    .item:nth-child(3) {
      background: linear-gradient(165deg, #F6E1B4, #D9C086);
      color: #684F3E;
    }

    .item:nth-child(4) {
      background: linear-gradient(165deg, #7591FA, #2F3AC7);
      color: #FFFFFF;
    }


  }
}

.option {
  padding-bottom: 80px;

  &:nth-child(odd) {
    background-color: #fff;

  }

  .table {
    margin: 0 50px;
    background-color: #fff;
    border-radius: 10px;
    // overflow: hidden;
    position: relative;

    .row-title {
      background: #EEEEEE;
      font-weight: bold;
      font-size: 18px;
      position: sticky;
      top: 58px;
      left: 0;
      z-index: 1;
      border-radius: 10px 10px 0 0;

      .item {
        padding: 26px 0;
      }

      .label {
        font-size: 18px;
      }

      .item:nth-child(2) {
        background: #FDF7ED;
        color: #333333;
        background: linear-gradient(165deg, #E6E6E6, #D0D0D0);
      }

      .item:nth-child(3) {
        background: linear-gradient(165deg, #F6E1B4, #D9C086);
        color: #684F3E;
      }

      .item:nth-child(4) {
        background: linear-gradient(165deg, #7591FA, #2F3AC7);
        color: #FFFFFF;
      }
    }
  }


  .row {
    display: flex;
    background-color: #fff;


    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 14px 0;

      .img {
        width: 128px;
        height: 72px;
        background: #C2C2C2;
        border-radius: 10px;
        cursor: pointer;

        img {
          width: 100%;
          border-radius: 10px;
          height: 100%
        }
      }

      .video {
        width: 128px;
        height: 72px;
        background: #435CB8;
        border-radius: 10px;
        cursor: pointer;


        video {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

    }


    .item:nth-child(3) {
      background: #FDF7ED;
      color: #684F3E;
    }

    .item:nth-child(4) {
      background: #EDEDFB;
      color: #2F3AC7;
    }

    .label {
      font-weight: bold;
      font-size: 16px;
      width: 320px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

    .append {
      flex: 0.9;
    }
  }

  .inspect {
    margin-top: 60px;
    padding: 0 50px;

    .item {
      display: flex;


      .img {
        margin-right: 59px;
      }

      .text {
        text-align: left;
        margin-bottom: 80px;

        h6 {
          font-size: 18px;
          margin-bottom: 30px;
          font-weight: bold;
          line-height: 20px;
        }

        p {
          line-height: 22px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
